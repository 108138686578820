import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Datepicker } from 'flowbite-react';
import useOnClickOutside from 'src/hooks/useClickOutSide/useClickOutside';

interface ICustomDatepicker
  extends Omit<
    React.ComponentProps<typeof Datepicker>,
    'onSelectedDateChanged' | 'value' | 'showClearButton'
  > {
  placeholder?: string;
  value?: Date;
  onSelectedDateChanged?: (date: Date | undefined) => void;
  showClearButton?: boolean;
}

const CustomDatepicker: React.FC<ICustomDatepicker> = ({
  placeholder,
  value,
  onSelectedDateChanged,
  showClearButton = false,
  ...rest
}) => {
  const ref = useRef(null);
  const { t } = useTranslation('tag');

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState<boolean>(false);
  const [datepickerKey, setDatepickerKey] = useState<number>(0);

  useOnClickOutside(ref, () => setIsDatepickerOpen(false));

  useEffect(() => {
    if (!selectedDate) {
      setDatepickerKey((k) => k + 1);
    }
  }, [selectedDate]);

  const handleDateChange = (selectedDate: Date | null) => {
   
    setSelectedDate(selectedDate != null ? selectedDate : undefined);
    setIsDatepickerOpen(false);
    onSelectedDateChanged?.(selectedDate != null ? selectedDate : undefined);
  };

  const handleClear = () => {
    setSelectedDate(undefined);
    setIsDatepickerOpen(false);
    onSelectedDateChanged?.(undefined);
  };

  return (
    <div ref={ref} className="relative mt-4 mb-8">
      <Datepicker
        {...rest}
        key={datepickerKey}
        open={isDatepickerOpen}
        onClick={() => setIsDatepickerOpen(true)}
        value={selectedDate || undefined || null}
        onChange={(date) => {
            handleDateChange(date);

        }}
        showClearButton={false}
        showTodayButton={false}
        theme={{
          popup: {
            root: {
              inner: 'inline-block rounded-lg bg-white p-4 shadow-lg dark:bg-gray-700 h-[368px]',
            },
          },
        }}
      />
      {isDatepickerOpen && showClearButton && (
        <button
          type="button"
          onClick={handleClear}
          className="absolute top-[370px] left-[150px] w-[130px] h-[38px] rounded-lg px-5 py-2 text-center text-sm font-medium focus:ring-4 focus:ring-cyan-300 border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 z-50"
        >
          {t('edit-tag-properties-datepicker-clear-btn')}
        </button>
      )}
      {placeholder && (
        <label className="absolute left-2 -top-4 text-gray-500 text-xs pointer-events-none">
          {placeholder}
        </label>
      )}
    </div>
  );
};

export default CustomDatepicker;
