import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  getCategoryMapping,
  getCategoryProperties,
} from '../../../../../../helpers/categoriesOptions';
import CategoriesTable from './components/CategoriesTable';
import { ICategories } from '../../../../../../store/services/categoryApi/categoryTypes';
  
interface ITagDetails {
  properties: Record<string, string>;
  selectedCategories?: string[];
  connectedCategories: string[];
  categoryList?: ICategories;
  isCategoriesSuccess: boolean;
}

export type ITagStates =
  | 'PROVISIONED'
  | 'COMMISSIONED'
  | 'STORED'
  | 'SERVICE'
  | 'TRANSPORT'
  | 'DISCARDED';

const TagDetails = ({
  properties,
  selectedCategories,
  connectedCategories,
  categoryList,
  isCategoriesSuccess,
}: ITagDetails) => {
  const { t } = useTranslation('tag');
  const naviagte = useNavigate();

  const categoryMapping = useMemo(
    () =>
      selectedCategories?.length && isCategoriesSuccess
        ? getCategoryMapping(selectedCategories, properties, categoryList?.categoryTypes)
        : [],
    [categoryList, selectedCategories, isCategoriesSuccess, properties],
  );

  const filteredConnectedCategories =
    connectedCategories?.filter(
      (connectedCategory) =>
        !categoryMapping.some(
          (selectedCategory) => selectedCategory.category === connectedCategory,
        ),
    ) || [];

  const handleCategoryClick = (category: string) => {
    naviagte(`/search?searchType=category&searchValue=${category}`);
  };

  return (
      <>
      {categoryMapping.map(({ category, categoryProperties }, index) => {
        return (
          <CategoriesTable
            key={`${category}-${index}`}
            category={category}
            categoryProperties={categoryProperties}
            rowClassNames='font-tag-font-family text-tag-font-color'
          />
        ) 
      })}
      {filteredConnectedCategories.map((category, index) => {
        const categoryProperties = getCategoryProperties(
          category,
          properties,
          categoryList?.categoryTypes,
        );
        return (
          <CategoriesTable
            key={`${category}-${index}`}
            category={category}
            categoryProperties={categoryProperties}
            rowClassNames='font-tag-font-family text-tag-font-color'
          />
        )
      })}
    </>
  );
};

export default React.memo(TagDetails);
