import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllowedAssetStatesQuery } from '../../../../../../store/services/configApi';
import { ISelectOptions } from 'src/types';
import { handleChangeSelect } from 'src/helpers/handleChangeFormik/handleChangeSelect';
import CustomSelect from 'src/components/CustomSelect';

interface ITagStateSelect {
  state: string;
  setFieldValue: any;
  field?: string;
}

const TagStateSelect = ({ state, setFieldValue, field = 'state' }: ITagStateSelect) => {
  const { t } = useTranslation('tag');
  const { data: tagStatesData } = useGetAllowedAssetStatesQuery({});

  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const stateOptions = useMemo(() =>
    tagStatesData?.map((state) => ({
      value: state,
      label: t(`asset-state-${state.toLowerCase()}-option`),
    })), [tagStatesData, t]
  );

  const selectedStateValue = stateOptions?.find((option) => option.value === state);

  useEffect(() => {
    setHasValue(!!state); 
  }, [state]);

  const handleSelectChange = useCallback(
    (option: ISelectOptions) => {
      handleChangeSelect({ option, field, setFieldValue });
      setHasValue(!!option); 
    },
    [field, setFieldValue]
  );

  return (
    <div className="relative">
      <CustomSelect
        isClearable
        placeholder=""
        value={selectedStateValue}
        options={stateOptions || []}
        onSelect={handleSelectChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <label
        className={`absolute left-[10px] top-[9px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
          isFocused || hasValue ? '!-top-4 text-xs' : 'text-base'
        }`}
        style={{ width: 'calc(100% - 40px)' }}
      >
        {t('edit_tag_state_placeholder')}
      </label>
    </div>
  );
};

export default React.memo(TagStateSelect);
