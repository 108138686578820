import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from 'src/helpers/reduxCommon';
import { setReturnTo } from 'src/store/slices/auth.slice';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth0();
  const location = useLocation();
  const dispatch = useAppDispatch();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    dispatch(setReturnTo(location.pathname + location.search));
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
