import React from 'react';
import { FormikProps } from 'formik';
import { ServerError } from 'src/store/storeTypes';
import FormWrapper from 'src/components/FormWrapper';
import ServerFormError from 'src/components/ServerFormError';
import ModalContainer from 'src/components/Modals/components/ModalContainer';
import ModalTitle from 'src/components/Modals/components/ModalTitle';
import SaveButton from 'src/components/SaveButton';
import CloseButton from 'src/components/CloseButton';

interface IBaseBatchForm {
  title: string;
  children: React.ReactNode;
  footerContent?: React.ReactNode;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: any;
  isOpened: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  submitForm: () => void;
}

const BaseBatchForm: React.FC<IBaseBatchForm> = ({
  title,
  children,
  footerContent,
  isSuccess,
  isLoading,
  isError,
  error,
  isOpened,
  onClose,
  handleSubmit,
  submitForm,
}) => {
  return (
    <ModalContainer isModalOpened={isOpened} onClose={onClose} isFormModal={true}>
      <ModalTitle title={title} />
      <FormWrapper onSubmit={handleSubmit}>
        {children}
        
        <div className="flex justify-center mt-4 pb-10 mb-10">
          <SaveButton submitFunc={submitForm} disabled={isLoading} />
          <CloseButton onClose={onClose} disabled={isLoading} />
        </div>

        {footerContent}

        <ServerFormError
          title="Error"
          requestError={error as ServerError}
          isError={isError}
        />
      </FormWrapper>
    </ModalContainer>
  );
};

export default BaseBatchForm;