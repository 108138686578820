import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCategoriesQuery } from 'src/store/services/categoryApi';
import i18n from 'i18next';
import { IMultiSelectOptions, IOptions } from 'src/types';
import { handleChangeMultiSelect } from 'src/helpers/handleChangeFormik/handleChangeMultiSelect';
import CustomSelect from 'src/components/CustomSelect';
import { categoriesOptions } from 'src/helpers/categoriesOptions';

interface ICategoriesSelect {
  categories: string[];
  setFieldValue: any;
}

const CategoriesSelect = ({ categories, setFieldValue }: ICategoriesSelect) => {
  const { data, isSuccess } = useGetCategoriesQuery({});
  const [categoryOptions, setCategoryOptions] = useState<IOptions[]>([]);
  const { t } = useTranslation('tag');
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(categories && !!categories.length);

  useEffect(() => {
    const loadCategoryOptions = async () => {
      const options = await categoriesOptions({
        categories: data,
        isSuccess: isSuccess,
      });
      setCategoryOptions(options);
    };

    loadCategoryOptions();

    const handleLanguageChange = () => {
      loadCategoryOptions();
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [data, isSuccess]);

  const selectedCategoriesValue = useMemo(
    () => categoryOptions.filter((option) => categories?.includes(option.value)),
    [categoryOptions, categories]
  );

  const handleSelectChange = (option: IMultiSelectOptions) => {
    handleChangeMultiSelect({ option, field: 'categories', setFieldValue });
    setHasValue(!!option.length);
  };

  return (
    <div className="relative">
      <CustomSelect
        placeholder=""
        value={selectedCategoriesValue}
        options={categoryOptions}
        isSearchable
        isMulti
        onSelect={handleSelectChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <label
        className={`absolute left-[10px] top-[9px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
          isFocused || hasValue ? '!-top-4 text-xs' : 'text-base'
        }`}
        style={{ width: 'calc(100% - 40px)' }}
      >
        {t('edit_tag_categories_placeholder')}
      </label>
    </div>
  );
};

export default React.memo(CategoriesSelect);
