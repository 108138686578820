import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

const EmptyAsset = () => {
  const { t } = useTranslation('tag');
  const params = useParams<'tag'>();

  return (
    <div className=" w-5/6 gap-2 bg-white  h-[300px]  m-auto flex items-center justify-center">
      <div className="text-[25px] px-10">
        <p>{t('no_asset_title', { tag: params.tag })}</p>
        <p>
          <Trans
            i18nKey="scan_again"
            ns="tag"
            components={{
              a: <Link className="text-[#0066f9] inline-block" to={'/?isOpenScanner=true'} />,
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default React.memo(EmptyAsset);
