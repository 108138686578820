import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditBatchAssetsMutation } from 'src/store/services/batchApi';
import { PatchBatchBodyData } from 'src/store/services/batchApi/batchTypes';
import EditBatchAssetsForm from './components/BatchForm/EditBatchAssetsForm/index';

interface IEditBatchAssetsModal {
  onClose: () => void;
  isOpened: boolean;
  data: PatchBatchBodyData;
}

const EditBatchAssetsModal: React.FC<IEditBatchAssetsModal> = ({
  data,
  isOpened,
  onClose
}) => {
  const { t } = useTranslation('batches');
  const [editBatchAssets, { isSuccess, isLoading, isError, error }] = useEditBatchAssetsMutation();

  const handleSubmit = async (values: any) => {
    try {
      await editBatchAssets({
        batchId: data.id,
        ...values
      }).unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to edit batch assets:', error);
    }
  };

  return (
    <EditBatchAssetsForm
      onSubmit={handleSubmit}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      data={data}
      isOpened={isOpened}
      onClose={onClose}
    />
  );
};

export default EditBatchAssetsModal;