import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCreateBatchMutation } from 'src/store/services/batchApi';
import ButtonWithIcon from '../../ButtonWithIcon';
import { ReactComponent as IconAdd } from 'src/assets/footer-menu/IconAdd.svg';
import DownloadButton from '../../DownloadButton';
import { ReactComponent as IconDownload } from 'src/assets/IconDownload.svg';
import { useLazyGetBatchTagsQuery } from '../../../store/services/batchApi';
import CreateBatchForm from './components/BatchForm/CreateBatchForm';
import { IFormType } from './components/BatchForm';
import { PatchBatchBodyData } from 'src/store/services/batchApi/batchTypes';

interface ICreateBatchModal {
  onClose?: () => void;
  isOpened?: boolean;
  data?: PatchBatchBodyData;
  creationType?: IFormType;
  showButtons?: boolean;
}

const CreateBatchModal: React.FC<ICreateBatchModal> = ({
  onClose: externalOnClose,
  isOpened: externalIsOpened,
  data,
  creationType = 'fromNewObjects',
  showButtons = true,
}) => {
  const { t } = useTranslation('layout');
  const navigate = useNavigate();
  const [internalIsModalOpened, setInternalModalOpened] = useState(false);
  
  const [createBatch, { data: createBatchData, isSuccess, isLoading, isError, error }] = 
    useCreateBatchMutation();
  const [triggerBatchQR, { data: fileUrl, isFetching }] = useLazyGetBatchTagsQuery();

  const isModalOpened = externalIsOpened ?? internalIsModalOpened;
  
  const handleClose = useCallback(() => {
    if (externalOnClose) {
      externalOnClose();
    } else {
      setInternalModalOpened(false);
    }
  }, [externalOnClose]);

  const handleSubmit = async (values: any) => {
    try {
      const result = await createBatch(values).unwrap();
      if (result?.id) {
        navigate(`/batch/${result.id}`);
      }
    } catch (error) {
      console.error('Failed to create batch:', error);
    }
  };

  return (
    <div className="flex">
      {showButtons && (
        <>
          <ButtonWithIcon
            className="flex items-center justify-center gap-1 py-[10px] px-5 rounded text-center text-tag border-2 border-solid border-tag transition-colors duration-300 ease-linear m-2"
            icon={<IconAdd className="w-6 h-6 fill-tag" />}
            text={t('batch-creation-text')}
            onClick={() => setInternalModalOpened(true)}
          />
          <DownloadButton 
            icon={<IconDownload className="w-6 h-6 fill-tag" />}
            text={t('download-qr-codes', {'ns': 'batches'})}
            downloadFilename='batch-qr-codes.csv'
            trigger={triggerBatchQR}
            fileUrl={fileUrl}
            isFetching={isFetching}
          />
        </>
      )}
      
      <CreateBatchForm
        isOpened={isModalOpened}
        onClose={handleClose}
        onSubmit={handleSubmit}
        isSuccess={isSuccess}
        isLoading={isLoading}
        isError={isError}
        error={error}
        formType={creationType}
        data={data}
      />
    </div>
  );
};

export default React.memo(CreateBatchModal);