import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { IBatchData } from '../../../../../store/services/batchApi/batchTypes';
import ChipWithIcon from '../../../../../components/ChipWithIcon';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconRegistration } from 'src/assets/home/IconRegistration.svg';
import { ReactComponent as IconTransport } from 'src/assets/IconTransport.svg';
import { ReactComponent as IconService } from 'src/assets/IconService.svg';
import { ReactComponent as IconAdd } from 'src/assets/footer-menu/IconAdd.svg';
import { ReactComponent as IconLayers } from 'src/assets/footer-menu/IconLayers.svg';
import { ReactComponent as IconPlanned } from 'src/assets/IconPlanned.svg';
import { ReactComponent as IconStart } from 'src/assets/IconStart.svg';
import { ReactComponent as IconDone } from 'src/assets/IconDone.svg';
import { ReactComponent as IconCounter } from 'src/assets/IconCounter.svg';
import { ReactComponent as IconQR } from 'src/assets/home/IconQR2.svg';
import { batchState, batchType } from 'src/store/services/batchApi/batchTypes';

interface IBatchRow {
  data: IBatchData;
}

const BatchRow = ({ data }: IBatchRow) => {
  const { id, name, description, type, state, timestamp, count, guid } = data;
  const naviagte = useNavigate();
  const { t } = useTranslation('batches');

  const handleOpenSingleBatch = useCallback(() => {
    naviagte(`/batch/${id}`);
  }, [id, naviagte]);


  const getStyle = (state: batchState) => {
    const style = state === 'IN_PROGRESS' ? { border: '2px solid #5F9548' } : 
    state === 'COMPLETED' ? { border: '2px solid #4C443C' } :
    state === 'PLANNED' ? { border: '2px dashed #5F9548' } : {};
    return style;
  }

  const getTypeIcon = (type: batchType, className: string) => 
    type === 'ASSET_CREATION' ? <IconAdd className={className} /> :
    type === 'REGISTRATION' ? <IconRegistration className={className} /> :
    type === 'TRANSPORT' ? <IconTransport className={className} /> :
    type === 'SERVICE' ? <IconService className={className} /> :
    type === 'COUNT' ? <IconCounter className={className} /> :
    <IconLayers className={className} />;

  const getStateIcon = (state: batchState, className: string) => 
    state === 'PLANNED' ? <IconPlanned className={className} /> :
    state === 'IN_PROGRESS' ? <IconStart className={className} /> :
    state === 'COMPLETED' ? <IconDone className={className} /> :
    <></>;


  return (
    <tr
      onClick={handleOpenSingleBatch}
      className="font-['Mukta_Mahee',_Arial,_sans-serif] cursor-pointer even:bg-gray-50 odd:bg-gray-10"
    >
      <td className="text-base p-[6px] text-left relative">
        {name}
      </td>
      <td className="hidden xl:table-cell text-[#555] p-[6px] text-left relative w-1/3">
        {description?.length > 50 ? description.substring(0, 50) +  "..." : description}
      </td>
      <td className="hidden md:table-cell text-[#555] p-[6px] text-center relative  w-32 ">{count}</td>

      <td className="hidden lg:table-cell  text-[#555] p-[6px] text-center relative  w-28">
        {guid?.length && (
           <ChipWithIcon icon={<IconQR className="w-4 h-4 fill-gray-500" />} text={(guid?.length) ? guid.split('-')[0] : ''}   className=' border font-mono text-[0.8em] m-auto py-1 px-0 m-0' />
        )}
        
        </td>

      <td className="hidden md:table-cell text-[#555] p-[6px] text-center relative">{timestamp}</td>
      <td className="text-[11px] text-[#555] p-[6px]  content-center w-32 ">
          <ChipWithIcon icon={getTypeIcon(type!, "w-5 h-5 fill-gray-500")} text={t(`batch-type-${type!.toLowerCase()}-option`).toUpperCase()}   className=' w-24 xl:w-32 border-dashed border m-auto py-1 px-0 px-2 m-0' />
      </td>
      <td className="text-[11px] text-[#555] p-[6px]  content-center  w-32 ">
          <ChipWithIcon icon={getStateIcon(state, "w-5 h-5 fill-gray-500")} text={t(`batch-state-${state.toLowerCase()}-option`).toUpperCase()}  className=' w-24 xl:w-32 py-1 px-0 px-2 m-0 m-auto' style={getStyle(state)} />
      </td>
    </tr>
  );
};

export default BatchRow;
