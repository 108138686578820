import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

interface IUseOpenScannerProps {
  openScanner: () => void;
}
const useOpenScanner = ({ openScanner }: IUseOpenScannerProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const isOpenScanner = params.get('isOpenScanner');

  useEffect(() => {
    if (isOpenScanner) {
      openScanner();
      navigate({
        pathname: location.pathname,
        search: '',
      });
    }
  }, [isOpenScanner, navigate, openScanner, location.pathname]);
};

export default useOpenScanner;
