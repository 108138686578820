import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditBatchMutation } from 'src/store/services/batchApi';
import { PatchBatchBodyData } from 'src/store/services/batchApi/batchTypes';
import EditBatchForm from './components/BatchForm/EditBatchForm';

interface IEditBatchModal {
  onClose: () => void;
  isOpened: boolean;
  data: PatchBatchBodyData; 
}

const EditBatchModal: React.FC<IEditBatchModal> = ({ 
  data, 
  isOpened, 
  onClose 
}) => {
  const { t } = useTranslation('batches');
  const [editBatch, { isSuccess, isLoading, isError, error }] = useEditBatchMutation();

  const handleSubmit = async (values: any) => {
    try {
      await editBatch({
        id: data.id,
        ...values
      }).unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to edit batch:', error);
    }
  };

  return (
    <EditBatchForm
      onSubmit={handleSubmit}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      data={data}
      isOpened={isOpened}
      onClose={onClose}
    />
  );
};

export default EditBatchModal;
