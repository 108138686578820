import React, { useState } from 'react';
import { useGetCategoriesQuery } from '../../../store/services/categoryApi';
import { getTypedCategories } from '../../../helpers/categoriesOptions';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '../../../components/CustomAccordion';

interface ISearchCategories {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseAccordions: () => void;
}

const SearchCategories = ({ isOpen, setIsOpen, handleCloseAccordions }: ISearchCategories) => {
  const { t } = useTranslation('search');
  const { data } = useGetCategoriesQuery({});
  const [showAll, setShowAll] = useState<boolean>(false);
  const naviagte = useNavigate();
  const categories = getTypedCategories(data);

  const maxInitialDisplay = 9;

  const visibleCategories = categories.slice(0, maxInitialDisplay) || [];
  const hiddenCategories = categories.slice(maxInitialDisplay) || [];

  const handleShowAllClick = () => {
    setShowAll(true);
  };

  return (
    <CustomAccordion
      title={t('title-categories')}
      isAccordionOpen={isOpen}
      setIsAccordionOpen={setIsOpen}
    >
      {visibleCategories.map(({ category, categoryType }, index, arr) => {
        const isNewCategory = categoryType !== arr[index - 1]?.categoryType && !!index;
        const handleCategoryClick = () => {
          naviagte(`/search?searchType=category&searchValue=${category}`);
          handleCloseAccordions();
        };

        return !isNewCategory ? (
          <p
            key={category}
            onClick={handleCategoryClick}
            className="inline-block py-1 px-2 my-2 mx-0.5 bg-category-badge transition-colors duration-300 ease-in-out text-xs text-category-badge-foreground-color text-center rounded-xl cursor-pointer"
          >
            {category}
          </p>
        ) : (
          <React.Fragment key={category}>
            <br />
            <p
              onClick={handleCategoryClick}
              className="inline-block py-1 px-2 my-2 mx-0.5 bg-category-badge transition-colors duration-300 ease-in-out text-xs text-category-badge-foreground-color text-center rounded-xl cursor-pointer"
            >
              {category}
            </p>
          </React.Fragment>
        );
      })}
      {hiddenCategories?.length > 0 && !showAll && (
        <p
          className="inline-block py-1 px-2 my-2 mx-0.5 bg-category-badge transition-colors duration-300 ease-in-out text-xs text-white text-center rounded-xl cursor-pointer"
          onClick={handleShowAllClick}
        >
          {t('search-batch-show-more-btn')} +
        </p>
      )}
      {showAll &&
        hiddenCategories.map(({ category, categoryType }, index, arr) => {
          const isNewCategory = categoryType !== arr[index - 1]?.categoryType && !!index;
          const handleCategoryClick = () => {
            naviagte(`/search?searchType=category&searchValue=${category}`);
          };

          return !isNewCategory ? (
            <p
              key={category}
              onClick={handleCategoryClick}
              className="inline-block py-1 px-2 my-2 mx-0.5 bg-category-badge transition-colors duration-300 ease-in-out text-xs text-category-badge-foreground-color text-center rounded-xl cursor-pointer"
            >
              {category}
            </p>
          ) : (
            <React.Fragment key={category}>
              <br />
              <p
                onClick={handleCategoryClick}
                className="inline-block py-1 px-2 my-2 mx-0.5 bg-category-badge transition-colors duration-300 ease-in-out text-xs text-category-badge-foreground-color text-center rounded-xl cursor-pointer"
              >
                {category}
              </p>
            </React.Fragment>
          );
        })}
    </CustomAccordion>
  );
};

export default React.memo(SearchCategories);
