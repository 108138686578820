import i18n from 'i18next';
import { ICategories, ICategoryType } from 'src/store/services/categoryApi/categoryTypes';
import { IOptions } from 'src/types';
import { CategoryMapping } from 'src/types/categoryMapping';

interface ICategoriesOptions {
  categories: ICategories | undefined;
  isSuccess?: boolean;
}

interface ICategoriesTypeOptions extends ICategoriesOptions {
  categoryType: string;
}

interface ITypedCategory {
  category: string;
  categoryType: string;
}

interface IAssetTypes {
  categories: ICategories;
  selectedCategories: string[];
}

export interface ILinkedProperty {
  property: string;
  propertyLink: string;
}

export const categoriesOptions = async ({
  categories,
  isSuccess,
}: ICategoriesOptions): Promise<IOptions[]> => {
  await i18n.loadNamespaces('tag');
  return isSuccess && categories?.categoryTypes
    ? Object.values(categories.categoryTypes).reduce<IOptions[]>((acc, item) => {
        Object.keys(item.instances).forEach((category) =>
          acc.push({
            value: category,
            label: i18n.t(`category-${category.toLowerCase().split(' ').join('-')}`, {
              defaultValue: category,
              ns: 'tag',
            }),
          }),
        );
        return acc;
      }, [])
    : [];
};

export const getCategoriesTypeOptions = async ({
  categories,
  categoryType,
}: ICategoriesTypeOptions): Promise<IOptions[]> => {
  await i18n.loadNamespaces('tag');
  return Object.keys(categories?.categoryTypes[categoryType]?.instances || {}).map((category) => ({
    value: category,
    label: i18n.t(`category-${category.toLowerCase().split(' ').join('-')}`, {
      defaultValue: category,
      ns: 'tag',
    }),
  }));
};

const getSelectedCategoryType = (category: string, categoryTypes?: Record<string, ICategoryType>) =>
  Object.values<ICategoryType>(categoryTypes || {}).find(
    (categoryType) => categoryType.instances[category],
  );

export const getCategoryProperties = (
  category: string,
  properties: Record<string, string>,
  categoryTypes?: Record<string, ICategoryType>,
) => {
  const selectedCategoryType = getSelectedCategoryType(category, categoryTypes);

  const assetPropertyList = Object.keys(selectedCategoryType?.assetPropertyTypes || {}).reduce(
    (acc, assetPropertyKey) => ({
      ...acc,
      [assetPropertyKey]: properties[assetPropertyKey],
    }),
    {},
  );
  return {
    ...assetPropertyList,
    ...(selectedCategoryType?.instances[category]?.properties || {}),
  };
};

export const getCategoryMapping = (
  selectedCategories: string[],
  properties: Record<string, string>,
  categoryTypes?: Record<string, ICategoryType>,
) =>
  selectedCategories.map((category) => ({
    category,
    categoryProperties: getCategoryProperties(category, properties, categoryTypes),
  }));

export const getSelectedCategoryMapping = ({ categories, selectedCategories }: IAssetTypes) =>
  selectedCategories.reduce<CategoryMapping[]>((acc, category) => {
    const selectedCategoryType = getSelectedCategoryType(category, categories?.categoryTypes);

    if (selectedCategoryType?.assetPropertyTypes) {
      acc.push({
        category,
        categoryProperties: selectedCategoryType?.assetPropertyTypes,
      });
    }
    return acc;
  }, []);

export const getTypedCategories = (categories: ICategories | undefined): ITypedCategory[] => {
  if (!categories) {
    return [];
  }
  const typedCategories: ITypedCategory[] = [];
  Object.entries(categories.categoryTypes).forEach(([categoryType, categoryTypeInfo]) => {
    Object.keys(categoryTypeInfo.instances).forEach((category) => {
      typedCategories.push({ category, categoryType });
    });
  });
  return typedCategories;
};

export const getLinkedProperties = (
  categories: string[],
  properties: Record<string, string>,
  categoryTypes?: Record<string, ICategoryType>,
): ILinkedProperty[] => {
  if (!categories) {
    return [];
  }

  let result: ILinkedProperty[] = [];
  let links: string[] = [];

  const categoryMapping = getCategoryMapping(categories, properties, categoryTypes);
  const categoryProperties = categoryMapping.map((category) => category.categoryProperties);

  categoryProperties.forEach((property: any) => {
    for (let propertyValue in property) {
      if (property[propertyValue] && typeof property[propertyValue] === 'string') {
        if (
          property[propertyValue].startsWith('http://') ||
          property[propertyValue].startsWith('https://')
        ) {
          let propertyObject: any = {};
          propertyObject[propertyValue] = property[propertyValue];
          if (links.indexOf(property[propertyValue]) === -1) {
            result.push(propertyObject);
            links.push(property[propertyValue]);
          }
        }
      }
    }
  });

  return result;
};
