import React from 'react';
import { useNavigate } from 'react-router';
import { ILocation } from '../../../../../store/services/locationApi/locationTypes';
import { useTranslation } from 'react-i18next';

interface ILocalesBadge {
  local: ILocation | null;
}

const LocalesBadge = ({ local }: ILocalesBadge) => {
  const naviagte = useNavigate();

  const { t } = useTranslation('tag');

  const handleBatchClick = (locationId: number) => {
    naviagte(`/location/${locationId}`);
  };
  return local?.id ? (
    <div className="flex flex-wrap gap-2">
     

      <button
        type="button"
        onClick={() => handleBatchClick(local?.id)}
        className="block py-1 px-2 my-2 mx-0.5
            bg-location-badge
            transition-colors duration-300 ease-in-out
            text-xs text-location-badge-foreground-color text-center
            rounded-xl
            cursor-pointer"
      >
        {t(`location`)}:&nbsp;{local.name}
      </button>
    </div>
  ) : (
    <></>
  );
};

export default React.memo(LocalesBadge);
