import React from 'react';
import { ReactComponent as IconClose } from 'src/assets/IconClose.svg';

interface ISearchedBadge {
  text: string;
  className?: string;
  onRemove?: () => void;
  passedRef?: React.RefObject<HTMLDivElement>;
}

const SearchedBadge = ({ text, className = '', onRemove, passedRef }: ISearchedBadge) => {
  return (
    <div
      ref={passedRef}
      onClick={onRemove}
      className={`flex items-center gap-1 py-1 px-2 bg-category-badge transition-colors duration-300 ease-in-out text-xs text-black text-center rounded-xl whitespace-nowrap cursor-pointer overflow-x-hidden ${className}`}
    >
      {text}
      <IconClose className="w-3 h-3" />
    </div>
  );
};

export default SearchedBadge;
