import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { BatchValidation } from '../batchValidation';
import { getBatchInitialValues } from '../getBatchInitialValues';
import BaseBatchForm from '../components/BaseBatchForm';
import FormField from 'src/components/FormField';
import CustomMDEditor from 'src/components/MDEditor';
import BatchQRScanner from '../components/BatchQRScanner';
import OrganizationsSelect from 'src/components/OrganizationsSelect';
import CategoriesSelect from '../../../../Tag/components/TagForm/components/CategoriesSelect';
import { PatchBatchBodyData } from 'src/store/services/batchApi/batchTypes';
import { getXAuthOwnerHeader } from 'src/helpers/getXAuthOwnerHeader';

interface IEditBatchForm {
  onSubmit: (values: any) => void;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: any;
  isOpened: boolean;
  onClose: () => void;
  data: PatchBatchBodyData; 
}

const EditBatchForm: React.FC<IEditBatchForm> = ({
  onSubmit,
  isSuccess,
  isLoading,
  isError,
  error,
  isOpened,
  onClose,
  data,
}) => {
  const { t } = useTranslation('batches');

  const formik = useFormik({
    initialValues: getBatchInitialValues(data),
    validationSchema: BatchValidation('editBatch'),
    onSubmit: (values) => {
      const payload = {
        body: {
          name: values.name,
          description: values.description || null,
          guid: values.guid || null,
          ...(values.type === 'COUNT' && {
            count: values.count,
            categories: values.categories,
          }),
        },
        batchId: data?.id,
        ...getXAuthOwnerHeader(values.organization),
      };
      onSubmit(payload);
    },
  });

  return (
    <BaseBatchForm
      title={t('edit-batch')}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      isOpened={isOpened}
      onClose={onClose}
      handleSubmit={formik.handleSubmit}
      submitForm={formik.submitForm}
    >
      <FormField
        value={formik.values.name}
        name="name"
        placeholder={t('batch-name-holder')}
        onChange={formik.handleChange}
        isError={!!formik.errors.name && !!formik.touched.name}
        errorMessage={(formik.touched.name && formik.errors?.name) || ''}
      />

      <div data-color-mode="light" className="markdown-editor mb-8">
        <CustomMDEditor
          id="batch-description-editor"
          label={t('batch-description-place-holder')}
          value={formik.values.description || ''}
          onChange={(value) => formik.setFieldValue('description', value)}
        />
      </div>
      {formik.touched.description && formik.errors.description && (
        <div className="error-message">{formik.errors.description}</div>
      )}

      <BatchQRScanner
        setFieldValue={formik.setFieldValue}
        guid={formik.values.guid}
        placeholder={t('batch-qr-code-placeholder')}
      />

      {formik.values.type === 'COUNT' && (
        <div>
          <FormField
            value={formik.values.count}
            name="count"
            placeholder={t('batch-counter-holder')}
            onChange={formik.handleChange}
            type="number"
            isError={!!formik.errors.count && !!formik.touched.count}
            errorMessage={(formik.touched.count && formik.errors?.count) || ''}
          />
          <CategoriesSelect
            categories={formik.values.categories || []}
            setFieldValue={formik.setFieldValue}
          />
        </div>
      )}

      <OrganizationsSelect
        setFieldValue={formik.setFieldValue}
        value={formik.values.organization}
        isError={!!formik.errors.organization && !!formik.touched.organization}
        errorMessage={(formik.touched.organization && formik.errors?.organization) || ''}
        isClearable
        placeholder={t('select-organization-placeholder')}
      />
    </BaseBatchForm>
  );
};

export default EditBatchForm;