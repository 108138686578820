import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { BatchValidation } from '../batchValidation';
import { getBatchInitialValues } from '../getBatchInitialValues';
import BaseBatchForm from '../components/BaseBatchForm';
import CustomMDEditor from 'src/components/MDEditor';
import TagStateSelect from 'src/components/Modals/Tag/components/TagForm/components/TagStateSelect';
import LocationSelect from 'src/components/Modals/Tag/components/TagForm/components/LocationSelect';
import CategoriesSelect from 'src/components/Modals/Tag/components/TagForm/components/CategoriesSelect';
import OrganizationsSelect from 'src/components/OrganizationsSelect';
import CustomAccordion from 'src/components/CustomAccordion';
import TakePhotoButton from 'src/components/Modals/Tag/components/TagForm/components/TakePhotoButton';
import UploadDocumentsButton from 'src/components/Modals/Tag/components/TagForm/components/UploadDocumentButton';
import { usePostBatchDocumentMutation } from 'src/store/services/batchApi';
import { getXAuthOwnerHeader } from 'src/helpers/getXAuthOwnerHeader';

interface IEditBatchAssetsForm {
  onSubmit: (values: any) => void;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: any;
  data: any;
  isOpened: boolean;
  onClose: () => void;
}

const EditBatchAssetsForm: React.FC<IEditBatchAssetsForm> = ({
  onSubmit,
  isSuccess,
  isLoading,
  isError,
  error,
  data,
  isOpened,
  onClose,
}) => {
  const { t } = useTranslation('batches');
  const [isUploadAccordionOpen, setIsUploadAccordionOpen] = React.useState(true);
  const [postBatchDocument] = usePostBatchDocumentMutation();

  const formik = useFormik({
    initialValues: getBatchInitialValues(data, true),
    validationSchema: BatchValidation('editBatchAssets', 'fromNewObjects'),
    onSubmit: (values) => {
      const payload = {
        body: {
          assetState: values.assetState || null,
          locationId: values.location?.id || null,
          categories: values.categories || null,
          description: values.assetsDescription || null,
        },
        batchId: data?.id,
        ...getXAuthOwnerHeader(values.organization),
      };
      onSubmit(payload);
    },
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    onClose();
  }, [formik, onClose]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      window.location.reload();
    }
  }, [isSuccess, onClose]);

  return (
    <BaseBatchForm
      title={t('edit-batch-assets')}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      isOpened={isOpened}
      onClose={handleClose}
      handleSubmit={formik.handleSubmit}
      submitForm={formik.submitForm}
      footerContent={
        data?.id && (
          <CustomAccordion
            isAccordionOpen={isUploadAccordionOpen}
            setIsAccordionOpen={setIsUploadAccordionOpen}
            accordionWrapperClassName="my-10"
            contentWrapperClassName="flex items-center justify-center"
            title={t('edit-upload-label', { ns: 'tag' })}
          >
            <TakePhotoButton
              assetId={data.id}
              onImageCapture={() => {}}
              buttonText={t('context-menu-take-photo-label', { ns: 'tag' })}
              className="m-5"
              isBatchMode={true}
            />

            <UploadDocumentsButton
              onUpload={async (formData) => {
                try {
                  await postBatchDocument({
                    batchId: data.id,
                    body: formData,
                  }).unwrap();
                } catch (error) {
                  console.error('Error during upload:', error);
                }
              }}
              buttonText={t('context-menu-upload-file-label', { ns: 'tag' })}
              className="m-5"
            />
          </CustomAccordion>
        )
      }
    >
      <div className="flex justify-center font-mukta-mahee font-bold text-lg">
        {t('batch-assets-update-notification')}
      </div>

      <div data-color-mode="light" className="markdown-editor mb-8">
        <CustomMDEditor
          id="assets-description-editor"
          label={t('assets-description-place-holder')}
          value={formik.values.assetsDescription || ''}
          onChange={(value) => formik.setFieldValue('assetsDescription', value)}
        />
      </div>

      <TagStateSelect
        state={formik.values.assetState}
        setFieldValue={formik.setFieldValue}
        field="assetState"
      />

      <LocationSelect 
        location={formik.values.location} 
        setFieldValue={formik.setFieldValue} 
      />

      <CategoriesSelect
        categories={formik.values.categories || []}
        setFieldValue={formik.setFieldValue}
      />

      <OrganizationsSelect
        setFieldValue={formik.setFieldValue}
        value={formik.values.organization}
        isError={!!formik.errors.organization && !!formik.touched.organization}
        errorMessage={(formik.touched.organization && formik.errors?.organization) || ''}
        isClearable
        placeholder={t('select-organization-placeholder')}
      />
    </BaseBatchForm>
  );
};

export default EditBatchAssetsForm;