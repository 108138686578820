import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import i18n from './i18next/i18next';
import { ROUTES } from './constants/routes';
import Layout from './components/Layout';
import TagProduct from 'src/views/Tag';
import { useAppDispatch, useAppSelector } from 'src/helpers/reduxCommon';
import { setAuthToken } from 'src/store/slices/auth.slice';
import Loading from 'src/components/Loading';
import Home from 'src/views/Home';
import Location from 'src/views/Location';
import LocationSingle from 'src/views/LocationSingle';
import Batches from 'src/views/Batches';
import BatchSingle from 'src/views/BatchSingle';
import Events from 'src/views/Events';
import Search from 'src/views/Search';
import PrivateRoute from 'src/components/HighOrderedComponents/PrivateRoute';
import VersionCheck from 'src/components/VersionCheck';
import useFetchUserLanguage from './hooks/useFetchUserLanguage';
import { setActiveOrg } from 'src/store/slices/activeOrg.slice';
import { User } from '@auth0/auth0-spa-js';

// styles
import useChangeTheme from './hooks/useChangeTheme';
import './App.css';

// fontawesome
import initFontAwesome from './utils/initFontAwesome';

initFontAwesome();

const App = () => {
  const dispatch = useAppDispatch();
  const isAuthLoading = useAppSelector((state) => state.auth.isLoading);
  const { user, isLoading, isAuthenticated, getAccessTokenSilently, error } = useAuth0();
  const { language, loading } = useFetchUserLanguage();

  useChangeTheme();

  const getToken = useCallback(async () => {
    if (!isLoading) {
      let token = '';
      if (isAuthenticated) {
        token = await getAccessTokenSilently();
        let organizations = (user as User)['https://sirk.app/organizations'] as string[]
        dispatch(setActiveOrg(organizations[0]))
      }
      dispatch(setAuthToken(token));
    }
  }, [dispatch, getAccessTokenSilently, isLoading, isAuthenticated, user]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  useEffect(() => {
    if (!loading) {
      i18n.changeLanguage(language);
    }
  }, [language, loading]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <div id="app" className="h-full z-0">
        <Routes>
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.TAG} element={<TagProduct />} />
          <Route element={<Layout><Outlet /></Layout>}>
            <Route path={ROUTES.BATCH_SINGLE} element={<PrivateRoute><BatchSingle /></PrivateRoute>} />
            <Route path={ROUTES.BATCHES} element={<PrivateRoute><Batches /></PrivateRoute>} />
            <Route path={ROUTES.SINGLE_LOCATION} element={<PrivateRoute><LocationSingle /></PrivateRoute>} />
            <Route path={ROUTES.LOCATION} element={<PrivateRoute><Location /></PrivateRoute>} />
            <Route path={ROUTES.EVENTS} element={<PrivateRoute><Events /></PrivateRoute>} />
            <Route path={ROUTES.SEARCH} element={<PrivateRoute><Search /></PrivateRoute>} />
            <Route path={ROUTES.CATEGORIES} element={<PrivateRoute><>{'error page'}</></PrivateRoute>} />
            <Route path={ROUTES.ERROR} element={<>{'error page'}</>} />
          </Route>
        </Routes>
        <VersionCheck />
      </div>
    </BrowserRouter>
  );
};

export default App;
