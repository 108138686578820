import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Processes from './components/Processes/index';
import UserProfile from './components/UserProfile'
import Latest from './components/Latest/index';
import AddTagModal from '../../components/Modals/Tag/AddTagModal';
import ServiceAndMaintenance from '../../components/ServiceAndMaintenance';
import Landing from '../Landing';
import Layout from '../../components/Layout';
import SearchField from '../../components/SearchField';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

const Home = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const naviagte = useNavigate();
  
  const [isScannerOpened, setIsScannerOpened] = useState<boolean>(false);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const { handleSubmit, values, handleChange } = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: (values) => {
      values.search = values.search.trim();
      const { search } = values;
      if (search[3] === '-' && search.length === 7) {
        naviagte(`/tag/${search}`);
      } else {
        naviagte(`/search?search=${search || ''}`);
      }
    },
  });

  const handleOpenScanner = () => {
    console.log('Scanner opened in Home');
    setIsScannerOpened(true);
  };

  const handleCloseScanner = () => {
    console.log('Scanner closed in Home');
    setIsScannerOpened(false);
  };

  if (!isLoading && !isAuthenticated) {
    return <Landing />;
  }

  return !isLoading && isAuthenticated ? (
    <Layout>
      <div className="relative h-auto w-full flex flex-col layout-container  p-4 space-y-4 pb-[100px]">

      <form onSubmit={handleSubmit} className="flex items-center justify-between xl:mb-10">
        <SearchField
          name="search"
          value={values.search}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          wrapperClassName='rounded border-2 border-indigo-200'
        />
      </form>
      <section className="xl:grid xl:grid-cols-2  lg:gap-10 xl:gap-20 xl:py-20">
      <UserProfile />
      <Processes
        isScannerOpened={isScannerOpened}
        handleScannerOpen={handleOpenScanner}
        handleScannerClose={handleCloseScanner}
        handlesAddTagModalOpen={() => setIsModalOpened(true)}
        handlesAddTagModalClose={() => setIsModalOpened(false)}
      />
      </section>
      <ServiceAndMaintenance 
        isOpen={true}
        showProvidersFor={true}
      />

      <Latest />

      <AddTagModal
        isOpened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
      />
      </div>
    </Layout>
  ) : null;
};

export default React.memo(Home);
