import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { BatchValidation } from '../batchValidation';
import { getBatchInitialValues } from '../getBatchInitialValues';
import BaseBatchForm from '../components/BaseBatchForm';
import FormField from 'src/components/FormField';
import OrganizationsSelect from 'src/components/OrganizationsSelect';
import { IFormType } from '..';
import CustomMDEditor from 'src/components/MDEditor';
import BatchQRScanner from '../components/BatchQRScanner';
import { PatchBatchBodyData } from 'src/store/services/batchApi/batchTypes';
import CategoriesSelect from 'src/components/Modals/Tag/components/TagForm/components/CategoriesSelect';
import BatchTagsMultiselect from '../components/BatchTagsMultiselect';
import BatchTypeSelect from '../components/BatchTypeSelect';
import { getXAuthOwnerHeader } from 'src/helpers/getXAuthOwnerHeader';

interface ICreateBatchForm {
  onSubmit: (values: any) => void;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: any;
  isOpened: boolean;
  onClose: () => void;
  formType?: IFormType;
  data?: PatchBatchBodyData;
}

const CreateBatchForm: React.FC<ICreateBatchForm> = ({
  onSubmit,
  isSuccess,
  isLoading,
  isError,
  error,
  isOpened,
  onClose,
  formType = 'fromNewObjects',
}) => {
  const { t } = useTranslation('batches');
  const [activeTab, setActiveTab] = useState<IFormType>(formType);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);

  const formik = useFormik({
    initialValues: getBatchInitialValues(),
    validationSchema: BatchValidation('default', activeTab),
    onSubmit: (values) => {
      const payload = {
        body: {
          name: values.name,
          description: values.description || null,
          guid: values.guid || null,
          ...(activeTab === 'fromNewObjects' && values.numberOfNewAssets && {
            numberOfNewAssets: values.numberOfNewAssets
          }),
          ...(activeTab === 'fromExistingObjects' && {
            assetIds: values.assetIds,
          }),
          ...(activeTab === 'counter' && {
            initialCount: values.count,
            categories: values.categories,
          }),
          type: values.type || 'ASSET_CREATION',
          ...(values.consecutiveAssetsCount && selectedTags.length === 1 && {
            consecutiveAssetIds: {
              assetId: selectedTags[0],
              count: values.consecutiveAssetsCount,
            }
          }),
        },
        ...getXAuthOwnerHeader(values.organization),
      };
      onSubmit(payload);
    },
  });

  return (
    <BaseBatchForm
      title={t('create-batch')}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      isOpened={isOpened}
      onClose={onClose}
      handleSubmit={formik.handleSubmit}
      submitForm={formik.submitForm}
    >
      <FormField
        value={formik.values.name}
        name="name"
        placeholder={t('batch-name-holder')}
        onChange={formik.handleChange}
        isError={!!formik.errors.name && !!formik.touched.name}
        errorMessage={(formik.touched.name && formik.errors?.name) || ''}
      />
      
      <div data-color-mode="light" className="markdown-editor mb-8">
        <CustomMDEditor
          id="batch-description-editor"
          label={t('batch-description-place-holder')}
          value={formik.values.description || ''}
          onChange={(value) => formik.setFieldValue('description', value)}
        />
      </div>
      {formik.touched.description && formik.errors.description && (
        <div className="error-message">{formik.errors.description}</div>
      )}

      <BatchQRScanner
        setFieldValue={formik.setFieldValue}
        guid={formik.values.guid}
        placeholder={t('batch-qr-code-placeholder')}
      />

<div className="lg:text-lg font-medium text-center text-gray-500 border-gray-200">
        <ul className="flex justify-center space-x-4 -mb-px">
          <li>
            <button
              onClick={() => {
                setActiveTab('fromNewObjects');
                formik.setFieldValue('type', 'ASSET_CREATION');
              }}
              className={`inline-block px-6 py-3 border-b-2 rounded-t-lg ${
                activeTab === 'fromNewObjects'
                  ? 'text-blue-600 border-gray-400 border-b-blue-600 rounded-t-lg border'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300'
              }`}
            >
              {t('batch-creation-new-text')}
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab('fromExistingObjects')}
              className={`inline-block px-6 py-3 border-b-2 rounded-t-lg ${
                activeTab === 'fromExistingObjects'
                  ? 'text-blue-600 border-gray-400 border-b-blue-600 rounded-t-lg border'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300'
              }`}
            >
              {t('batch-creation-existing-text')}
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setActiveTab('counter');
                formik.setFieldValue('type', 'COUNT');
              }}
              className={`inline-block px-6 py-3 border-b-2 rounded-t-lg ${
                activeTab === 'counter'
                  ? 'text-blue-600 border-gray-400 border-b-blue-600 rounded-t-lg border'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300'
              }`}
            >
              {t('batch-creation-counter-text')}
            </button>
          </li>
        </ul>
      </div>

      {activeTab === 'fromNewObjects' && (
        <div className="border-2 border-gray-400 rounded-md p-6 mb-6 bg-white shadow-xl h-80">
          <FormField
            value={formik.values.numberOfNewAssets}
            name="numberOfNewAssets"
            placeholder={t('batch-number-holder')}
            onChange={formik.handleChange}
            type="number"
            isError={!!formik.errors.numberOfNewAssets && !!formik.touched.numberOfNewAssets}
            errorMessage={(formik.touched.numberOfNewAssets && formik.errors?.numberOfNewAssets) || ''}
          />
        </div>
      )}

      {activeTab === 'fromExistingObjects' && (
        <div className="border-2 border-gray-400 rounded-md p-6 mb-6 bg-white shadow-xl h-80">
          <BatchTagsMultiselect
            setFieldValue={formik.setFieldValue}
            placeholder={t('select-tags-placeholder')}
            onChange={(tags) => setSelectedTags(tags)}
          />

          <FormField
            value={formik.values.consecutiveAssetsCount}
            name="consecutiveAssetsCount"
            placeholder={t('consecutive-assets-placeholder')}
            onChange={formik.handleChange}
            type="number"
            isError={!!formik.errors.consecutiveAssetsCount && !!formik.touched.consecutiveAssetsCount}
            errorMessage={(formik.touched.consecutiveAssetsCount && formik.errors?.consecutiveAssetsCount) || ''}
            className={`w-1/4 ${
              selectedTags.length !== 1 || formik.values.numberOfNewAssets === undefined || formik.values.numberOfNewAssets === 0
                ? 'bg-gray-200'
                : 'bg-white'
            }`}
          />

          <BatchTypeSelect
            type={formik.values.type}
            setFieldValue={formik.setFieldValue}
            isError={!!formik.errors.type && !!formik.touched.type}
            errorMessage={(formik.touched.type && formik.errors?.type) || ''}
          />
        </div>
      )}

      {activeTab === 'counter' && (
        <div className="border-2 border-gray-400 rounded-md p-6 mb-6 bg-white shadow-xl h-80">
          <FormField
            value={formik.values.count}
            name="count"
            placeholder={t('batch-counter-holder')}
            onChange={formik.handleChange}
            type="number"
          />
          <CategoriesSelect
            categories={formik.values.categories || []}
            setFieldValue={formik.setFieldValue}
          />
        </div>
      )}

      <OrganizationsSelect
        setFieldValue={formik.setFieldValue}
        value={formik.values.organization}
        isError={!!formik.errors.organization && !!formik.touched.organization}
        errorMessage={(formik.touched.organization && formik.errors?.organization) || ''}
        isClearable
        placeholder={t('select-organization-placeholder')}
      />
    </BaseBatchForm>
  );
};

export default CreateBatchForm;