import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectOptions } from 'src/types';
import CustomSelect from 'src/components/CustomSelect';
import { handleChangeSelect } from 'src/helpers/handleChangeFormik/handleChangeSelect';
import { useGetLocationQuery } from 'src/store/services/locationApi';
import { ILocation } from 'src/store/services/locationApi/locationTypes';

interface ILocationSelect {
  location?: ILocation | null;
  setFieldValue: any;
}

const LocationSelect = ({ location, setFieldValue }: ILocationSelect) => {
  const { t } = useTranslation('tag');
  const { data, isSuccess } = useGetLocationQuery({});
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(!!location);

  const locationOptions = useMemo(
    () => (isSuccess ? data?.map((location) => ({ value: location, label: location.name })) : []),
    [data, isSuccess],
  );

  const selectedLocationValue = locationOptions.find((option) => option.value.id === location?.id);

  const handleSelectChange = (option: ISelectOptions) => {
    handleChangeSelect({ option, field: 'location', setFieldValue });
    setHasValue(!!option?.value); 
  };

  return (
    <div className="relative">
      <CustomSelect
        isClearable
        placeholder=""
        value={selectedLocationValue}
        options={locationOptions}
        onSelect={handleSelectChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <label
        className={`absolute left-[10px] top-[9px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
          isFocused || hasValue ? '!-top-4 text-xs' : 'text-base'
        }`}
        style={{ width: 'calc(100% - 40px)' }} 
      >
        {t('edit_tag_location_placeholder')}
      </label>
    </div>
  );
};

export default React.memo(LocationSelect);
