import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyGetLocationQuery } from '../../store/services/locationApi';
import { useLazyGetTagQuery } from '../../store/services/tagsApi';
import { useLazyGetBatchByIdQuery, useLazyGetBatchesQuery } from '../../store/services/batchApi';

import { Html5Qrcode } from 'html5-qrcode';
import { IMultiSelectOptions } from '../../types';
import { IExternalId } from '../../store/services/tagsApi/tagTypes';
import { skippedScannerExceptions } from 'src/constants/skippedScannerExceptions';
import { companiesMapping, externalCompaniesMapping } from 'src/constants/companiesMapping';
import { IBatchData } from 'src/store/services/batchApi/batchTypes';

import './styles.module.css';

interface IScanner {
  scannerMode?: IScannerMode;
  selectedAssets?: IMultiSelectOptions;
  selectedExternalIds?: IMultiSelectOptions;
  setFieldValue?: any;
  onClose?: () => void;
}

export type IScannerMode = 'asset' | 'batch' | 'location' | 'assetMultiselect' | 'assetExternal'| 'serialAssets' | 'batchQR';

const Scanner = ({
  scannerMode = 'asset',
  selectedAssets,
  selectedExternalIds,
  setFieldValue,
  onClose,
}: IScanner) => {
  const [triggerLocationByTag, { data: locationData, isError: isLocationError }] = useLazyGetLocationQuery();
  const [triggerBatchByTag, { data: batchData, isError: isBatchError }] = useLazyGetBatchesQuery();
  const [triggerBatchById] = useLazyGetBatchByIdQuery();
  const [triggerTag, { data: tagData }] = useLazyGetTagQuery();
  const navigate = useNavigate();
  const html5QrCodeRef = useRef<Html5Qrcode | null>(null);
  const [scannerError, setScannerError] = useState('');
  const [isDone, setIsDone] = useState(false);

  useLayoutEffect(() => {
    html5QrCodeRef.current = new Html5Qrcode('reader');
  }, []);

  useEffect(() => {
    if (isLocationError) {
      setScannerError(
        `Could not find ${
          scannerMode !== 'assetMultiselect' ? scannerMode : 'asset'
        } with that tag`,
      );
    }
  }, [isLocationError, setScannerError, scannerMode]);

  useEffect(() => {
    const config = {
      fps: 5,
      qrbox: (scannerWidth: number, scannerHeight: number) => {
        const qrboxWidth = Math.max(scannerWidth * 0.7, 50);
        const qrboxHeight = Math.max(scannerHeight * 0.8, 50);

        return {
          width: qrboxWidth,
          height: qrboxHeight,
        };
      },
    };

    const handleScannerFailure = (error: string) => {
      if (!skippedScannerExceptions[error]) {
        setScannerError(error);
      }
    };

    const startScan = async () => {
      try {
        await html5QrCodeRef.current?.start(
          { facingMode: 'environment' },
          config,
          handleScannerSuccess,
          handleScannerFailure,
        );
      } catch (error: any) {
        setScannerError(error);
      }
    };

    const stopScan = () => {
      if (html5QrCodeRef.current && html5QrCodeRef.current.isScanning) {
        html5QrCodeRef.current.stop();
      }
    };

    const extractIdentifier = (url: string): string | IExternalId | null => {
      const batchUrlPattern = /https:\/\/(test-)?b\.sirk\.app\/batch\/([a-f0-9-]+)/i;
      const batchMatch = url.match(batchUrlPattern);
      if (batchMatch) {
        return batchMatch[2];
      }
  
      if (Object.keys(companiesMapping).some((companyUrl) => url.includes(companyUrl))) {
        const identifier = url.split('/');
        const id = identifier[identifier.length - 1];
  
        let result: string | IExternalId = id;
  
        Object.entries(externalCompaniesMapping).forEach(([externalSystemUrl, externalSystem]) => {
          if (url.includes(externalSystemUrl)) {
            result =
              scannerMode === 'assetExternal'
                ? {
                    systemName: externalSystem,
                    id,
                    urlStringFormat: externalSystemUrl,
                  }
                : `${id}?externalSystem=${externalSystem}`;
          }
        });
        return result;
      }
      return null;
    };

    const handleScannerSuccess = (decodedText: any) => {
      const id = extractIdentifier(decodedText);
      if (id) {
        const batchUrlPattern = /https:\/\/(test-)?b\.sirk\.app\/batch\//i;
        if (scannerMode === 'batchQR') {
          if (batchUrlPattern.test(decodedText)) {
            stopScan();
            if (setFieldValue) {
              setFieldValue('guid', id);
            }
            onClose?.();
            return;
          }
        }
        
        if (batchUrlPattern.test(decodedText)) {
          stopScan();
          
          triggerBatchById(id)
            .unwrap()
            .then((data: IBatchData) => {
              const batchExists = data.guid === id;
              
              if (batchExists) {
                navigate(`/batch/${id}`); 
                onClose?.();
              } else {
                setScannerError('Could not find batch with that QR code');
                startScan();
              }
            })
            .catch(() => {
              setScannerError('Could not find batch with that QR code');
              startScan();
            });
          
          return;
        }

        switch (scannerMode) {
          case 'batch':
            triggerBatchByTag({ tag: id }).then(
              ({ data }) => !!data && navigate(`/batch/${data[0].id}`),
            );
            break;
          case 'location':
            triggerLocationByTag({ tag: id }).then(
              ({ data }) => !!data && navigate(`/location/${data[0].id}`),
            );
            break;
          case 'asset':
            navigate(`/tag/${id}`);
            onClose?.();
            break;
          case 'assetMultiselect':
            triggerTag({ tag: id }).then(({ data }) => {
              !!data &&
                selectedAssets &&
                setFieldValue &&
                setFieldValue([
                  ...selectedAssets,
                  {
                    label: data.name,
                    value: data.id,
                  },
                ]);
              onClose?.();
            });
            break;
          case 'serialAssets':
            triggerTag({ tag: id }).then(({ data }) => {
              !!data &&
                setFieldValue &&
                setFieldValue(
                  {
                    label: data.tag,
                    value: data.id,
                  },
                  data,
                );
              onClose?.();
            });
            break;
          case 'assetExternal':
            selectedExternalIds &&
              setFieldValue &&
              setFieldValue([
                ...selectedExternalIds,
                {
                  label: (id as IExternalId).id,
                  value: id as IExternalId,
                },
              ]);
            onClose?.();
            break;
          case 'batchQR':
            if (setFieldValue) {
              setFieldValue('guid', id);
            }
            
            onClose?.();
            break;
        }
        setIsDone(true);
        return;
      } else {
        setScannerError(
          `Could not recognize ${
            scannerMode === 'batchQR' ? 'batch QR code' : 'asset tag'
          } in url ${decodedText}`
        );
        stopScan();
        return;
      }
    };


    if (!isDone) {
      startScan();
    }

    return () => {
      stopScan();
    };
  }, [
    navigate,
    scannerMode,
    triggerLocationByTag,
    triggerBatchById,
    onClose,
    selectedAssets,
    selectedExternalIds,
    setFieldValue,
    triggerTag,
    isDone,
    setIsDone,
    triggerBatchByTag
  ]);

  return (
    <div className="flex flex-col justify-center scanner w-full h-full">
      <div
        id="reader"
        className="absolute left-1/2 w-[350px] md:w-[650px] -translate-x-1/2 overflow-hidden"
      />
      {scannerError && (
        <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
          {scannerError}
        </div>
      )}
    </div>
  );
};

export default Scanner;
